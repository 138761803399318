function AppStage5Text() {

  return (
    <div className="pres-text col AppStage4Text">
     <h2>Final Stage</h2>
     <h3>Control Panel with Gains</h3>
      <p>
        The final user interface allows a user to change the gain on 
        3 of the available 8 zones. The UI monitors the levels of inputs, the names of 
        the inputs, the gain, and the name of each zone.
      </p>
      <p>
        It is possible to change the values of the device using AES70 Explorer 
        and see changes in the Control Panel. Visit <a href="https://eu.aes70x.net/" target="_blank" rel="noopener">eu.aes70x.net</a> and 
        explore the '8 Zone Virtual Smartpi', update Zone Names, Level Names, or the Input Description. 
        Observe updated values in the <a href="/app-stage5">Final Control Panel UI</a>.
      </p>
      <p>
        Thanks for stopping by. For any questions, feel free to ping Matt on matt@deuso.de.
      </p>
    </div>
  );
}

export default AppStage5Text;
