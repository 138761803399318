import { getBackendValue, DynamicValue, map } from '@deutschesoft/awml/src/index.pure';

//array zones (obj) / input channels are hard coded
const channels = [
  { zone: 1 },
// { zone: 2 },
// { zone: 3 },
// { zone: 4 }
];

const DeviceModel = channels.map(({zone}, index) => {

// `Smartpi` is defined in useBackend argument
	return {
	    index: index,
	    labeldata$: getBackendValue(`Smartpi:Slot 1/DSP/Zones/${ zone }/Name/Setting`) as DynamicValue<string>,
	  };
});

export default DeviceModel;
