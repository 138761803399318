import { 
  NavLink,
} from "react-router-dom";

export default function RootLayout() {
  return (
    <div className="root-layout">
      <nav>
        <NavLink to={`/`}>Home</NavLink>
      </nav>
      <main>
        Path Not Found
      </main>
    </div>
  )
}
