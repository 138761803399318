import './App.css';
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PresText from './Pres-text';
import AppEmpty from './stage1/App-empty';
import AppStage2 from './stage2/App-stage2';
import AppStage3 from './stage3/App-stage3';
import AppStage4 from './stage4/App-stage4';
import AppStage5 from './stage5/App-stage5';
import RootLayout from './layouts/RootLayout';
import RootLayoutError from './layouts/RootLayoutError';

const router = createBrowserRouter([
{
  element: <RootLayout/>,
  errorElement: <RootLayoutError/>,
  children: [
    {
      path: "/",
      element: <PresText/>,
    },
    {
      path: "app-empty",
      element: <AppEmpty />,
    },
    {
      path: "app-stage2",
      element: <AppStage2 />,
    },
    {
      path: "app-stage3",
      element: <AppStage3 />,
    },
    {
      path: "app-stage4",
      element: <AppStage4 />,
    },
    {
      path: "app-stage5",
      element: <AppStage5 />,
    },
  ],
},
]);

function App() {
return (
  <RouterProvider router={router} />
);
}

export default App;
