function AppStage4Text() {

  return (
    <div className="pres-text col AppStage4Text">
     <h2>Stage 4</h2>
     <h3>Build a Channel</h3>
      <p>
        In this stage, additional widgets are added, and additional paths
        are added to the DeviceModel. Widgets can have varying amounts of 
        complexity. Once the ChannelModel is ready, it can be replicated 
        many times in the DeviceModel.
      </p>
    </div>
  );
}

export default AppStage4Text;
