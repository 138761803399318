import Label from './widgets/Label';
import ChannelModel from './ChannelModel';

interface ChannelProperties {
  model: ChannelModel;
}

function Channel({ model }: ChannelProperties) {
  return (
      <Label labeldata$={ model.labeldata$ } />
  );
}

export default Channel;
