const slides = [
  {
    id: '1',
    src: "./assets/slides/AES70-client-server-model.jpg",
    title: "The AES70 Standard provides a Client-Server type model, a controller and controllable devices"
  },
  {
    id: '1-1',
    src: "./assets/slides/architecture-cross-platform.jpg",
    title: "The AES70 Standard provides a Client-Server type model, a controller and controllable devices"
  },
  {
    id: '2',
    src: "./assets/slides/architecture-technologies.jpg",
    title: "The demonstration uses open source frameworks for the frontend controller",
  },
  {
    id: '3',
    src: "./assets/slides/architecture-diagram.jpg",
    title: "Underlying technologies and custom code provide easy results for controlling OCA Devices",
  },
  {
    id: '3-1',
    src: "./assets/slides/aes70explorer.png",
    title: "Discovery OCA Device trees and locate Object Paths",
  },
  {
    id: '4',
    src: "./assets/slides/result-control-panel.jpg",
    title: "Touch Panel controller for 3 zones of audio output",
  },
];


export default slides;