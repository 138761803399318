function AppStage3Text() {

  return (
    <div className="pres-text col AppStage3Text">
     <h2>Stage 3</h2>
     <h3>Get AES70 Values</h3>
      <p>
        In this stage, models and widgets are added: 
        ChannelModel, DeviceModel, and the 
        definition for rendering the data in a Widget (Label).
      </p>
      <p>
          A key aspect to getting and setting values on an OCA Device is
          knowing the path to the device parameter itself. Use AES70 Explorer 
          to mount a device and expose all OCA Objects [See presentation slide]. Open the device object 
          tree and find a path, this path is added to the DeviceModel.
      </p>
    </div>
  );
}

export default AppStage3Text;
