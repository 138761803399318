import React from 'react';
import { AES70Backend } from '@deutschesoft/awml/src/backends/aes70';
import { useBackend } from '@deutschesoft/use-aux-widgets';
import Channel from './Channel';
import DeviceModel from './DeviceModel';
import AppStage4Text from './App-stage4-text';

class DebugAES70Backend extends AES70Backend{
  log(...args:any[]){
    console.log(...args);
  }
}

function connectSmartpiBackend() {
  return connectDevicesBackends(1058);
}

// add console info if path fails
function connectDevicesBackends(port:number) {
  return new DebugAES70Backend({
    url: `ws://${window.location.hostname}:${port}`, 
  });
}

function AppStage4() {
  const [ backend, ] = useBackend('Smartpi', connectSmartpiBackend); 

  const connected = !!backend;

  const model = ( connected ) ? DeviceModel : [];

  return (
    <div className="App stage4 newcol">
      <AppStage4Text />
      <fieldset>
        <legend>Result</legend>
        { model.map((model) => <Channel key={ model.index } model={ model }/>) }
        { ( !connected ) && <div>Connecting Stage 4</div> }
      </fieldset>
    </div>
  );
}

export default AppStage4;
