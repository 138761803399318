import React from 'react';
import AppStage2Text from './App-stage2-text';
import { AES70Backend } from '@deutschesoft/awml/src/backends/aes70';
import { useBackend } from '@deutschesoft/use-aux-widgets';

class DebugAES70Backend extends AES70Backend{
  log(...args:any[]){
    console.log(...args);
  }
}

function connectSmartpiBackend() {
  return connectDevicesBackends(1058);
}

// add console info if path fails
function connectDevicesBackends(port:number) {
  return new DebugAES70Backend({
    url: `ws://${window.location.hostname}:${port}`, 
  });
}

function AppStage2() {
  const [ backend, ] = useBackend('Smartpi', connectSmartpiBackend); 

  const connected = !!backend;

  return (
    <div className="App stage2 newcol">
      <AppStage2Text/>
      <fieldset>
        <legend>Result</legend>
          { ( connected ) && <div>Connected!</div> }
          { ( !connected ) && <div>Connecting Stage 2</div> }
      </fieldset>
    </div>
  );
}

export default AppStage2;
