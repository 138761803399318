function AppStage2Text() {

  return (
    <div className="pres-text col AppStage2Text">
     <h2>Stage 2</h2>
     <h3>Connected using AES70</h3>
      <p>
        In this stage, add the imports for AES70Backend, useBackend and 
        the code to establish the connection. Notice the application connects 
        to the machine it runs on. So there needs to be a websocket-proxy to 
        route AES70 packets to the network device. See README for setting up 
        your own local websocket-proxy. 
      </p>
    </div>
  );
}

export default AppStage2Text;
