import React from 'react';

import AppEmptyText from './App-empty-text';

function AppEmpty() {

  return (
    <div className="App stage1 newcol">
    <AppEmptyText/>
    <fieldset>
      <legend>Result</legend>
      <div>Empty React App</div>
    </fieldset>
    </div>
  );
}

export default AppEmpty;
