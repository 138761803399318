function AppEmptyText() {

  return (
    <div className="pres-text col AppEmptyText">
      <h2>Stage 1</h2>
      <h3>Empty React App</h3>
      <p>
        The initial step is to create an empty React Application.
      </p>
    </div>
  );
}

export default AppEmptyText;
