import { 
  NavLink,
  Outlet 
} from "react-router-dom";

export default function RootLayout() {
  return (
    <div className="root-layout">
      <nav>
        <NavLink to={`/`}>ISE 2023 OCA Presentation</NavLink>
        <NavLink to={`/app-empty`}>Stage 1 - Empty App</NavLink>
        <NavLink to={`/app-stage2`}>Stage 2 - Connect</NavLink>
        <NavLink to={`/app-stage3`}>Stage 3 - Get AES70 Values</NavLink>
        <NavLink to={`/app-stage4`}>Stage 4 - Build Channel</NavLink>
        <NavLink to={`/app-stage5`}>Final Result - Control Panel</NavLink>
        <a className="imprint" href="https://deuso.de/impressum/" rel="noopener">Copyright &copy; 2023 - DeusO GmbH Impressum</a>
      </nav>
      <div></div>
      <main>
        <Outlet/>
      </main>
    </div>
  )
}
