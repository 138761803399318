const images = [
  { 
    src: './assets/photos/oca-alliance-booth-2023.jpg', 
    title: 'OCA Alliance Booth Barcelona ISE 2023', 
    description: 'Jeff Berryman discussing OCA with attendees' 
  }, 
  { 
    src: './assets/photos/aes70-demo-stand.jpg', 
    title: 'OCA Devices',
    description: 'Demo stand with devices that use AES70 for control' 
  },
  { 
    src: './assets/photos/matt-hardy.jpg', 
    title: 'Matt Hardy', 
    description: 'Matt Hardy presenting the Build AES70 Controller UIs with Open Source Software talk' 
  },
];

export default images;