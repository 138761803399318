import React, { useState } from 'react';
import images from './media/images';
import slides from './media/slides';
import { useSpringCarousel } from 'react-spring-carousel';

function Images() {
  return (
    <div className="booth-images-3col">
      { images.map(({src, title, description}) => <img src={src} title={title} alt={description} />) }
    </div>
  );
}


function PresText() {
  const [activeItem, setActiveItem] = useState(0);
  const { 
    carouselFragment, 
    thumbsFragment,
    slideToPrevItem, 
    slideToNextItem,
    slideToItem,
    useListenToCustomEvent,
    getCurrentActiveItem
  } = useSpringCarousel({
    initialActiveItem: 0,
    withLoop: true,
    withThumbs: true, 
    items: slides.map((i, index) => ({
      id: i.id,
      renderItem: (
        <div className="slideItem">
          <img src={i.src} />
        </div>
      ),
      renderThumb: (
        <div className={`slideThumb ${activeItem === index ? "active": ""}`}>
          <img src={i.src} onClick={() => slideToItem(i.id)} />
        </div>
      )
    }))
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      const currActiveItem = getCurrentActiveItem();
      setActiveItem(currActiveItem.index);
    }
  });

  return (
    <div className="pres-text">
      <h1>Build AES70 Controller UIs with Open Source Software</h1>
      <p>
        This website contains the OCA Alliance Booth presentation by Matt Hardy at Barcelona, ISE 2023.
        Matt demonstrated how to monitor and control 
        a Smartpi DSP using AES70 with the following open source code libraries:
      </p>
      <ul>
        <li>
        <a href="https://react.dev" rel="noopener" target="_blank">React</a>  
        </li> 
        <li>
        <a href="https://github.com/DeutscheSoft/aux-widgets" rel="noopener" target="_blank">AUX Control Widgets</a>           
        </li> 

        <li>
        <a href="https://github.com/DeutscheSoft/AES70.js" rel="noopener" target="_blank">AES70.js</a> 
        </li> 
      </ul>          
      <p>
        This website demo connects to a Virtual OCA Device in the cloud. <br />
        The device named '8 Zone Virtual Smartpi' can be controlled independently
        using <a href="https://eu.aes70x.net" rel="noopener" target="_blank">AES70 Explorer</a>.
      </p>
      <p>
    <a href="https://github.com/DeutscheSoft/ise-2023-aes70-demo-ui" rel="noopener" target="_blank">Download</a> the 
        project source code for the 'Final UI' 
        on <a href="https://github.com/DeutscheSoft/ise-2023-aes70-demo-ui" rel="noopener" target="_blank">Github.com</a>.
      </p>
      <h2>OCA Alliance Booth - #7F100 Barcelona ISE 2023</h2>
      <Images />
      <h2>The Presentation Slides</h2>
      <div className="slides">
          {carouselFragment}
          <div className="thumbsContainer">{thumbsFragment}</div>
          <div className="pnbuts">
            <button onClick={slideToPrevItem}><span>&#8249;</span> Previous</button>
            <button onClick={slideToNextItem}>Next <span>&#8250;</span></button>
          </div>
      </div>
    </div>
  );
}

export default PresText;
